import TiptapEditor from "@aptedge/lib-ui/src/components/Editor/TiptapEditor";
import AEIcon from "@aptedge/lib-ui/src/components/GMIcon/Icon";
import { Icons } from "@aptedge/lib-ui/src/components/Icon/Icons";
import { Spinner } from "@aptedge/lib-ui/src/components/Spinner/Spinner";
import { LightTooltip } from "@aptedge/lib-ui/src/components/Tooltip/LightTooltip";
import {
  ArticleEditType,
  ArticleStatus,
  ArticleStatusLabel,
  UserRole
} from "@aptedge/lib-ui/src/types/entities";
import ClearIcon from "@mui/icons-material/Clear";
import DoneIcon from "@mui/icons-material/Done";
import PlagiarismOutlinedIcon from "@mui/icons-material/PlagiarismOutlined";
import TaskOutlinedIcon from "@mui/icons-material/TaskOutlined";
import classNames from "classnames";
import truncate from "lodash/truncate";
import React, { forwardRef, PropsWithChildren } from "react";
import { useViewArticleModal } from "../../hooks/useViewArticleModal";
import { ArticleMarkdownDisplay } from "../ArticlesReviewPage/ArticleMarkdownDisplay";
import { ArticleActions } from "./ArticleActions";
import { ArticleDetails } from "./ArticleDetails";
import { DiscardSource } from "./DiscardModal";
import styles from "./ViewArticleModal.module.scss";

interface ViewArticleModalProps {
  articleId: string;
  handleClose: () => void;
  checkArticleContentChanged: (isArticleContentChanged: boolean) => void;
  showConfirmationModal: boolean;
  handleUnsavedChanges: () => void;
}

export const ARTICLE_STATUS_BUTTON = [
  {
    icon: <TaskOutlinedIcon className={styles.articleStatusIcon} />,
    label: ArticleStatusLabel.PUBLISHED,
    selected: false,
    status: ArticleStatus.PUBLISHED
  },
  {
    icon: <AEIcon name="edit_document" size="1.25rem" />,
    label: ArticleStatusLabel.DRAFT,
    selected: false,
    status: ArticleStatus.DRAFT
  },
  {
    icon: <PlagiarismOutlinedIcon className={styles.articleStatusIcon} />,
    label: ArticleStatusLabel.IN_REVIEW,
    selected: false,
    status: ArticleStatus.IN_REVIEW
  },
  {
    icon: <AEIcon name="scan_delete" size="1.25rem" />,
    label: ArticleStatusLabel.DEPRECATED,
    selected: false,
    status: ArticleStatus.DEPRECATED
  }
];

const ViewArticleModal = forwardRef<
  HTMLDivElement,
  PropsWithChildren<ViewArticleModalProps>
>(
  (
    {
      articleId,
      handleClose,
      checkArticleContentChanged,
      showConfirmationModal,
      handleUnsavedChanges
    },
    ref
  ) => {
    const {
      kbGenPublishProcess,
      isLoading,
      isTitleEdit,
      isBodyEdit,
      article,
      currentUserRole,
      currentUser,
      title,
      body,
      articleStatusAndIcon,
      showArticleActions,
      handleEditArticle,
      handleTitleChange,
      handleCancelEdit,
      handleBodyChange,
      handleSaveEdit,
      handleUnsavedCancel
    } = useViewArticleModal(
      articleId,
      checkArticleContentChanged,
      handleUnsavedChanges
    );

    return (
      <div className={styles.container}>
        <div
          ref={ref}
          className={classNames(styles.contentContainer, {
            [styles.contentContainerV2]: kbGenPublishProcess
          })}
          data-testid="article-popup"
        >
          {isLoading && (
            <div className={styles.spinnerContainer}>
              <Spinner />
            </div>
          )}
          {article && (
            <>
              {kbGenPublishProcess && article && (
                <div className={styles.articleSubHeading}>
                  <span>
                    {article.status !== ArticleStatus.DRAFT
                      ? "Review article"
                      : "Save article"}
                  </span>
                  <ClearIcon onClick={handleClose} data-testid="close-popup" />
                </div>
              )}
              {!kbGenPublishProcess ? (
                <>
                  <div className={styles.articleHeader}>
                    <h3>{title}</h3>
                    <button onClick={handleClose}>
                      <Icons.Times size="lg" />
                    </button>
                  </div>
                  <div className={styles.articleContent}>
                    <ArticleMarkdownDisplay articleBody={body} />
                  </div>
                </>
              ) : (
                <div
                  className={classNames(styles.articleContent, {
                    [styles.articleContentV2]: kbGenPublishProcess,
                    [styles.articleContentWithoutActions]:
                      kbGenPublishProcess && !showArticleActions
                  })}
                >
                  <div className={styles.bodyLeftContainer}>
                    <div className={styles.headerContainer}>
                      {!isTitleEdit ? (
                        <LightTooltip
                          title={title}
                          placement="top"
                          arrow
                          componentsProps={{
                            tooltip: {
                              sx: {
                                width: "200px"
                              }
                            }
                          }}
                        >
                          <h3
                            className={styles.title}
                            onClick={() =>
                              handleEditArticle(ArticleEditType.TITLE)
                            }
                          >
                            {truncate(title, { length: 50 })}
                          </h3>
                        </LightTooltip>
                      ) : (
                        <div
                          className={styles.titleInputContainer}
                          data-testid="edit-popup-title"
                        >
                          <input
                            defaultValue={title}
                            onChange={handleTitleChange}
                          />
                          <div className={styles.inputActions}>
                            <div
                              onClick={() =>
                                handleSaveEdit(ArticleEditType.TITLE)
                              }
                              className={styles.saveBtn}
                              data-testid="save-edit-popup-title"
                            >
                              <DoneIcon />
                              <span>Save</span>
                            </div>
                            <div
                              onClick={() =>
                                handleCancelEdit(ArticleEditType.TITLE)
                              }
                              className={styles.cancelBtn}
                              data-testid="cancel-edit-popup-title"
                            >
                              <ClearIcon />
                              <span>Cancel</span>
                            </div>
                          </div>
                        </div>
                      )}
                      {!isTitleEdit && currentUserRole === UserRole.AGENT && (
                        <div
                          className={classNames(styles.articleStatusButton, {
                            [styles.draftArticle]:
                              articleStatusAndIcon.status ===
                              ArticleStatus.DRAFT,
                            [styles.deprecatedArticle]:
                              articleStatusAndIcon.status ===
                              ArticleStatus.DEPRECATED,
                            [styles.publishedArticle]:
                              articleStatusAndIcon.status ===
                              ArticleStatus.PUBLISHED,
                            [styles.inReviewArticle]:
                              articleStatusAndIcon.status ===
                              ArticleStatus.IN_REVIEW
                          })}
                          data-testid="article-popup-status"
                        >
                          {articleStatusAndIcon.icon}
                          <span>{articleStatusAndIcon.label}</span>
                        </div>
                      )}
                    </div>
                    {!isBodyEdit ? (
                      <div
                        onClick={() => handleEditArticle(ArticleEditType.BODY)}
                      >
                        <ArticleMarkdownDisplay articleBody={body} />
                      </div>
                    ) : (
                      <div
                        className={styles.bodyTextareaContainer}
                        data-testid="edit-popup-body"
                      >
                        <TiptapEditor
                          initialContent={body}
                          onChange={handleBodyChange}
                        />
                        <div className={styles.inputActions}>
                          <div
                            onClick={() => handleSaveEdit(ArticleEditType.BODY)}
                            className={styles.saveBtn}
                            data-testid="save-edit-popup-body"
                          >
                            <DoneIcon />
                            <span>Save</span>
                          </div>
                          <div
                            onClick={() =>
                              handleCancelEdit(ArticleEditType.BODY)
                            }
                            className={styles.cancelBtn}
                            data-testid="cancel-edit-popup-body"
                          >
                            <ClearIcon />
                            <span>Cancel</span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className={styles.bodyRightContainer}>
                    <ArticleDetails
                      article={article}
                      currentUserId={currentUser?.id}
                      currentUserRole={currentUserRole}
                    />
                  </div>
                </div>
              )}
              {showArticleActions && (
                <ArticleActions
                  article={article}
                  title={title}
                  body={body}
                  handleClose={handleClose}
                  discardSource={DiscardSource.ARTICLE_POPUP}
                />
              )}
            </>
          )}
        </div>
        {showConfirmationModal && (
          <>
            <div className={styles.backdrop}></div>
            <div className={styles.confirmationContainer}>
              <span className={styles.confirmationHeader}>Save Changes</span>
              <span className={styles.confirmationBody}>
                You have unsaved changes that may be lost if you leave this
                page. Are you sure you want to continue without saving?
              </span>
              <div className={styles.confirmationActions}>
                <button className={styles.confirm} onClick={handleClose}>
                  Yes
                </button>
                <button className={styles.cancel} onClick={handleUnsavedCancel}>
                  No
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
);

export { ViewArticleModal };
