import { useFlags } from "@aptedge/lib-ui/src/context/FlagsContext/FlagsContext";
import {
  useAppDispatch,
  useAppSelector
} from "@aptedge/lib-ui/src/redux/hook/hook";
import { updateArticleStatus } from "@aptedge/lib-ui/src/redux/reduxSlice/kbGenSlice";
import {
  ArticleEditType,
  ArticleStatus,
  ArticleStatusBtn,
  GeneratedKnowledgeResult,
  IUser,
  UserRole
} from "@aptedge/lib-ui/src/types/entities";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import turndown from "turndown";
import { fetchArticle } from "../clients/GeneratedKnowledge/fetchArticle";
import { useAuth } from "../context/AuthContext";
import { ARTICLE_STATUS_BUTTON } from "../pages/ArticlesPage/ViewArticleModal";

const turndownService = new turndown();
turndownService.addRule("strikethrough", {
  filter: ["s", "strike", "del"] as (keyof HTMLElementTagNameMap)[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  replacement: function (content: any) {
    return "~~" + content + "~~";
  }
});

type UseViewArticleModalProps = {
  kbGenPublishProcess: boolean;
  isLoading: boolean;
  isTitleEdit: boolean;
  isBodyEdit: boolean;
  title: string;
  body: string;
  article?: GeneratedKnowledgeResult;
  currentUserRole: UserRole;
  currentUser?: IUser;
  articleStatusAndIcon: ArticleStatusBtn;
  showArticleActions: boolean;
  handleEditArticle: (editType: ArticleEditType) => void;
  handleTitleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleCancelEdit: (editType: ArticleEditType) => void;
  handleBodyChange: (content: string) => void;
  handleSaveEdit: (editType: ArticleEditType) => void;
  handleUnsavedCancel: () => void;
};

function useViewArticleModal(
  articleId: string,
  checkArticleContentChanged: (isArticleContentChanged: boolean) => void,
  handleUnsavedChanges: () => void
): UseViewArticleModalProps {
  const { flags } = useFlags();
  const { kbGenPublishProcess } = flags;
  const { user: currentUser } = useAuth();
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [originalTitle, setOriginalTitle] = useState("");
  const [originalBody, setOriginalBody] = useState("");
  const [isTitleEdit, setIsTitleEdit] = useState(false);
  const [isBodyEdit, setIsBodyEdit] = useState(false);

  const { currentUserRole } = useAppSelector((state) => state.app);

  const dispatch = useAppDispatch();

  const articleQuery = useQuery(["article", articleId], () =>
    fetchArticle({ articleId })
  );

  const article = articleQuery?.data;
  const isLoading = articleQuery?.isLoading;

  const articleStatusAndIcon =
    ARTICLE_STATUS_BUTTON.find(
      (btn) => btn.status.toLowerCase() === article?.status.toLowerCase()
    ) || ({} as ArticleStatusBtn);

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setTitle(e.target.value);
  };

  const handleBodyChange = (content: string): void => {
    const markdownFromHtml = turndownService.turndown(content);
    setBody(markdownFromHtml);
  };

  const handleEditArticle = (editType: ArticleEditType): void => {
    if (
      article &&
      article.status !== ArticleStatus.DRAFT &&
      currentUserRole === UserRole.AGENT
    )
      return;
    switch (editType) {
      case ArticleEditType.TITLE:
        setIsTitleEdit(true);
        break;
      case ArticleEditType.BODY:
        setIsBodyEdit(true);
        break;
    }
  };

  const handleSaveEdit = (editType: ArticleEditType): void => {
    switch (editType) {
      case ArticleEditType.TITLE:
        setOriginalTitle(title);
        setIsTitleEdit(false);
        checkArticleContentChanged(title !== article?.title);
        break;
      case ArticleEditType.BODY:
        setOriginalBody(body);
        setIsBodyEdit(false);
        checkArticleContentChanged(body !== article?.body.body);
        break;
    }
  };

  const handleCancelEdit = (editType: ArticleEditType): void => {
    switch (editType) {
      case ArticleEditType.TITLE:
        if (article) {
          setTitle(originalTitle);
        }
        setIsTitleEdit(false);
        break;
      case ArticleEditType.BODY:
        if (article) {
          setBody(originalBody);
        }
        setIsBodyEdit(false);
        break;
    }
  };

  const handleUnsavedCancel = (): void => {
    handleUnsavedChanges();
  };

  const showArticleActions = Boolean(
    ((article?.status === ArticleStatus.DRAFT &&
      article.authorEmail === currentUser?.email) ||
      currentUserRole !== UserRole.AGENT) &&
      kbGenPublishProcess
  );

  useEffect(() => {
    if (article) {
      setTitle(article.title);
      setBody(article.body.body);
      setOriginalTitle(article.title);
      setOriginalBody(article.body.body);
      dispatch(updateArticleStatus(articleStatusAndIcon.status));
    }
  }, [article, articleStatusAndIcon, dispatch]);

  return {
    kbGenPublishProcess,
    isLoading,
    isTitleEdit,
    isBodyEdit,
    title,
    body,
    article,
    currentUserRole,
    currentUser,
    articleStatusAndIcon,
    showArticleActions,
    handleEditArticle,
    handleTitleChange,
    handleCancelEdit,
    handleBodyChange,
    handleSaveEdit,
    handleUnsavedCancel
  };
}

export { useViewArticleModal };
