import { Spinner } from "@aptedge/lib-ui/src/components/Spinner/Spinner";
import { IUserListing } from "@aptedge/lib-ui/src/types/entities";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { Input, InputAdornment } from "@mui/material";
import truncate from "lodash/truncate";
import { FC, RefObject, MouseEvent, ChangeEventHandler } from "react";
import { Manager, Popper, Reference } from "react-popper";
import {
  UserSearchSource,
  useArticleSearchUser
} from "../../hooks/useArticleSearchUser";
import styles from "./ArticleSearchUser.module.scss";

type ArticleSearchUserProps = {
  userInput: IUserListing;
  handleClosePopup?: () => void;
  articleUser: string;
  handleUserSearchClear: (articleUser: string) => void;
  placeholder: string;
  source: UserSearchSource;
};

const ArticleSearchUser: FC<ArticleSearchUserProps> = ({
  userInput,
  handleClosePopup,
  articleUser,
  handleUserSearchClear,
  placeholder,
  source
}) => {
  const {
    containerRef,
    inputRef,
    textInput,
    popupOpen,
    isLoading,
    sortedUsers,
    handleMouseDown,
    togglePopup,
    handleInputChange,
    handleClear,
    handleOptionClick
  } = useArticleSearchUser(
    userInput,
    articleUser,
    handleUserSearchClear,
    source,
    handleClosePopup
  );

  return (
    <div ref={containerRef} onMouseDown={handleMouseDown}>
      <Manager>
        <Reference>
          {({ ref }) => (
            <div ref={ref} onClick={togglePopup}>
              <ArticleUserInput
                inputRef={inputRef}
                placeholder={placeholder}
                textInput={textInput}
                handleInputChange={handleInputChange}
                handleClear={handleClear}
              />
            </div>
          )}
        </Reference>
        {popupOpen && (
          <ArticleUserPopup
            isLoading={isLoading}
            sortedUsers={sortedUsers}
            handleOptionClick={handleOptionClick}
          />
        )}
      </Manager>
    </div>
  );
};

export { ArticleSearchUser };

type ArticleUserInputProps = {
  inputRef: RefObject<HTMLInputElement>;
  placeholder: string;
  textInput: string;
  handleInputChange: ChangeEventHandler<HTMLInputElement>;
  handleClear: (e: MouseEvent<HTMLDivElement>) => void;
};

const ArticleUserInput: FC<ArticleUserInputProps> = ({
  inputRef,
  placeholder,
  textInput,
  handleInputChange,
  handleClear
}) => (
  <Input
    ref={inputRef}
    data-testid="user-search-input"
    className={styles.searchUser}
    inputProps={{ className: styles.userInput }}
    placeholder={placeholder}
    fullWidth={true}
    autoFocus={false}
    disableUnderline={true}
    value={textInput}
    onChange={handleInputChange}
    startAdornment={
      <InputAdornment position="start">
        <SearchOutlinedIcon className={styles.searchIcon} />
      </InputAdornment>
    }
    endAdornment={
      <>
        {textInput && (
          <InputAdornment position="end" onClick={handleClear}>
            <ClearOutlinedIcon className={styles.clearIcon} />
          </InputAdornment>
        )}
      </>
    }
  />
);

type ArticleUserPopupProps = {
  isLoading: boolean;
  sortedUsers: IUserListing[];
  handleOptionClick: (index: number) => void;
};

const ArticleUserPopup: FC<ArticleUserPopupProps> = ({
  isLoading,
  sortedUsers,
  handleOptionClick
}) => (
  <Popper placement="bottom-start">
    {({ ref, style, placement }) => (
      <div className="popper">
        <div
          className={styles.selectContent}
          ref={ref}
          style={style}
          data-placement={placement}
          onMouseDown={(e) => e.preventDefault()}
          data-testid="user-list-menu"
        >
          <div className={styles.suggestSearchResults}>
            {isLoading && (
              <div>
                <Spinner />
              </div>
            )}
            {sortedUsers &&
              sortedUsers.map((user: IUserListing, index: number) => (
                <div
                  role="button"
                  onClick={() => handleOptionClick(index)}
                  key={index}
                  className={styles.userDetail}
                >
                  <span className={styles.userInitial}>
                    {user.email.slice(0, 1).toUpperCase()}
                  </span>
                  <span className={styles.userEmail}>
                    {truncate(user.email, { length: 25 })}
                  </span>
                </div>
              ))}
          </div>
        </div>
      </div>
    )}
  </Popper>
);
